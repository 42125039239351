<template>
  <b-container fluid class="bg-login full-height">
    <b-row class="full-height">
      <b-col cols="12" md="6" class="left-side"> </b-col>
      <b-col cols="12" md="6" class="right-side">
        <div class="inner-wrapper" data-anima="right" v-if="!loading">
          <span class="logo mb-5">
            <img src="@/assets/logo.png" />
          </span>

          <b-form @submit.prevent="onSubmit" novalidate>
            <b-form-group label="Seu e-mail" label-for="email">
              <b-form-input
                id="email"
                placeholder="exemplo@exemplo.com"
                v-model="email"
                type="email"
                name="email"
                v-validate="'required|email'"
              ></b-form-input>
              <b-form-invalid-feedback :state="!errors.has('email')">
                Por favor, insira seu e-mail.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Senha de Acesso" label-for="password">
              <b-form-input
                id="password"
                placeholder="••••••••"
                v-model="password"
                type="password"
                name="password"
                v-validate="'required'"
              ></b-form-input>
              <span
                class="mostrar-senha"
                ref="mostrar_senha"
                @click="mostrarSenha"
                >Mostrar</span
              >
              <b-form-invalid-feedback :state="!errors.has('password')">
                Por favor, insira sua senha.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-invalid-feedback :state="success">
              E-mail ou senha estão incorretos.
            </b-form-invalid-feedback>

            <b-row class="justify-content-end mt-3">
              <router-link
                :to="{ name: 'Seller-Password-Recovery' }"
                class="recupera-senha-text"
                >Esqueci minha senha
              </router-link>
            </b-row>

            <b-row class="mt-4">
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="btn-acoes"
              >
                <router-link
                  :to="{ name: 'Seller-Register' }"
                  class="recupera-senha-text"
                  >Não tenho conta</router-link
                >

                <Captcha ref="Captcha" @changeVerify="changeVerify">
                  <BaseButton
                    variant="secondary"
                    ref="button"
                    :disabled="loading"
                    type="submit"
                    v-if="!loginClub && !loginEnvios"
                    class="btn-login"
                    @click="onSubmit"
                  >
                    Acessar
                  </BaseButton>

                  <BaseButton
                    variant="secondary"
                    v-else
                    ref="button"
                    :disabled="loading"
                    @click="externalSubmit"
                    class="btn-login"
                  >
                    Acessar
                  </BaseButton>
                </Captcha>
              </b-overlay>
            </b-row>

            <b-row class="mt-5">
              <a
                class="links-help"
                href="https://www.voomp.com.br/politica-de-privacidade-greenn/"
                target="_blank"
                >Política de Privacidade</a
              >
            </b-row>
          </b-form>
        </div>

        <div
          v-if="loading"
          class="py-4 d-flex justify-content-center align-items-center"
        >
          <b-spinner variant="dark" label="Spinning"></b-spinner>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import AuthServiceClub from "@/services/resources/AuthServiceClub";
import AuthServiceEnvios from "@/services/resources/AuthServiceEnvios";
const serviceClub = AuthServiceClub.build();
const serviceEnvios = AuthServiceEnvios.build();
import Captcha from "@/components/security/Captcha.vue";

export default {
  name: "Login",
  components: {
    Captcha,
  },
  data() {
    return {
      email: "",
      password: "",
      success: true,
      loading: false,
      loginClub: false,
      loginEnvios: false,
      urlClub: "",
      showPassword: false,
      hash: null,
      id_product: null,
      errorCaptch: false,
      captcha: false,
      manager: null,
    };
  },
  methods: {
    changeVerify(e) {
      this.captcha = e;
      if (this.loginClub || this.loginEnvios) {
        this.externalSubmit("get");
        return;
      }
      this.onSubmit("get");
    },
    mostrarSenha() {
      const input = document.querySelector("#password");
      this.showPassword = !this.showPassword;
      if (this.showPassword) {
        input.setAttribute("type", "text");
        this.$refs.mostrar_senha.innerText = "Ocultar";
      } else {
        input.setAttribute("type", "password");
        this.$refs.mostrar_senha.innerText = "Mostrar";
      }
    },
    existClubParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const type = urlParams.get("type");
      const url = urlParams.get("redirectTo");
      if (type === undefined || type === "") {
        this.loginClub = false;
        this.loginEnvios = false;
      } else if (type === "club") {
        this.loginClub = true;
        if (url !== undefined && url !== "") {
          this.urlClub = url;
        }
      } else if (type === "envios") {
        this.loginEnvios = true;
      }
    },
    externalSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.success = true;

      var service = "";
      if (this.loginClub) {
        service = serviceClub;
      } else {
        service = serviceEnvios;
      }

      service
        .login(this.email, this.password, this.captcha)
        .then((resp) => {
          if (resp.string) {
            if (this.loginClub) {
              if (
                this.urlClub !== "" &&
                this.urlClub !== null &&
                this.urlClub !== undefined
              ) {
                window.location.href =
                  "http://" +
                  this.urlClub +
                  "?rdr=" +
                  resp.string +
                  "&type=greenn";
              } else {
                window.location.href =
                  process.env.VUE_CLUB_API_HOST +
                  "?rdr=" +
                  resp.string +
                  "&type=greenn";
              }
            } else if (this.loginEnvios) {
              window.location.href =
                process.env.VUE_ENVIOS_API_HOST +
                "/login?rdr=" +
                resp.string +
                "&type=greenn";
            }
          } else {
            this.success = false;
            this.loading = false;
          }
        })
        .catch(() => {
          this.success = false;
          this.loading = false;
        });
    },
    onSubmit(get) {
      if (get !== "get") {
        this.$refs.Captcha.setCaptcha();
        return;
      }
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.success = true;
          this.loading = true;
          let data = {
            username: this.email,
            password: this.password,
            captcha: this.captcha,
          };

          this.$store
            .dispatch("loginRequest", data)
            .then(() => {
              if (this.hash) {
                this.$router.push(
                  `/recrutamento/${this.hash}/?p_id=${this.id_product}&manager=${this.manager}&confirmOpen=open`
                );
              } else {
                this.$router.push(`/verificar-email${window.location.search}`);
              }
            })
            .catch((err) => {
              console.log(err);
              this.$refs.Captcha.resetCaptcha();
            })
            .finally(() => {
              this.success = false;
              this.submit = false;
              this.loading = false;
            });
        }
      });
    },

    onHidden() {
      // Return focus to the button once hidden
      this.$refs.button.focus();
    },
  },
  updated() {
    const crisp = document.querySelector(".crisp-client");
    if (crisp) {
      setTimeout(() => {
        crisp.style.display = "none";
      });
    }
  },

  mounted() {
    this.existClubParams();

    if (typeof this.$route.query.hash_a != "undefined") {
      this.hash = this.$route.query.hash_a;
    }
    if (typeof this.$route.query.p_id != "undefined") {
      this.id_product = this.$route.query.p_id;
    }
    if (typeof this.$route.query.manager != "undefined") {
      this.manager = this.$route.query.manager;
    }

    if (typeof this.$route.query.email != "undefined") {
      this.email = this.$route.query.email;
      const input = document.querySelector("#password");
      if (input) {
        input.focus();
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.text-green {
  color: $base-color;
  font-weight: bold;
}

.bg-login {
  background: $base-color;
  overflow: hidden;
}

.form-group {
  position: relative;
}
.form-group + .form-group {
  margin-top: 20px;
}

.left-side {
  background-image: url("~@/assets/img/login-bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.right-side {
  background: #fff;
  padding: 20px 0px;
  height: 100%;

  .inner-wrapper {
    margin-top: 90px;
    padding: 6% 18%;

    .logo {
      img {
        width: 120px;
        height: auto;
        margin-bottom: 40px;
      }
    }
  }
}
.recupera-senha-text {
  color: #81858e;
  font-size: 14px;
}
.links-help {
  font-size: 12px;
  color: #81858e;
  margin-top: 20px;
}
.btn-acoes {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mostrar-senha {
  font-size: 13px;
  position: absolute;
  top: 55%;
  right: 20px;
  cursor: pointer;
  color: #81858e;
}
@media screen and (max-width: 768px) {
  .right-side .inner-wrapper {
    padding: 6% 11%;
  }
  .grecaptcha-badge {
    right: -194px !important;
  }
  .logo img {
    width: 80px !important;
    margin-bottom: 30px !important;
  }
  .links-help {
    margin: 0;
  }
}
</style>
